import {
  shallowRef, computed, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { SET_MODAL_OVERLAY_VISIBILITY } from '@atomic-ui/modalOverlay';
import { ModalObject, IModal } from '@types/defenitions'; // eslint-disable-line no-unused-vars

const modals = shallowRef([]);

/**
 *
 * @returns {IModal}
 */
export function useModal() {
  const store = useStore();

  /**
   *
   * @param {ModalObject} modalObj
   * @returns {void} - This function does not return anything
   */
  function showModal(modalObj) {
    modals.value = [...modals.value, modalObj];

    nextTick(() => {
      store.commit(`modalOverlayStore/${SET_MODAL_OVERLAY_VISIBILITY}`, modalObj.id);
    });
  }

  /**
   *
   * @param {string} id - Id selector of modal that is supposed to be closed
   * @returns {void} - This function does not return anything
   */
  function closeModal(id) {
    const filtered = modals.value.filter((_modal) => _modal.id !== id);
    modals.value = [...filtered];
  }

  const modal = computed(() => modals.value[0]);

  return {
    showModal,
    closeModal,
    modal,
    modals,
    isModalAvailable: computed(() => !!modal.value),
  };
}

export default useModal;
