/* istanbul ignore file */
import { moreLikeThisStore, wishlistStore } from '@common-vue/product-thumbnail';
import { modalOverlayStore } from '@atomic-ui/modalOverlay';
import visualFilterStore from '@common-vue/canvas-aem/src/store_modules/visualFilter/store';
import { recommendationStore } from '@common-vue/pros-collection';
import { imageMapStore } from '@common-vue/canvas-aem/src/components/imageMap';
import googleAdStore from '@common-vue/site-monetization/src/modules/googleAds/store';
import sponsoredItemsStore from '@common-vue/site-monetization/src/modules/sponsoredItems/store';
import encodeSearchKeyword from '@utils/encodeSearchKeyword';
import FilteringFacetsStore from '@components/FilteringFacets/store';
import { brand } from 'config';

export default {
  namespaced: true,
  state() {
    return {
      pagination: [
        {
          key: 'Sortby',
          value: 'ORIGINAL',
        },
        {
          key: 'Pageindex',
          value: 1,
        },
        {
          key: 'Productsperpage',
          value: 60,
        },
      ],
      pzoEligibleItems: {},
      filterOptimizationEnabled: false,
    };
  },
  getters: {
    getRowSortableGrid(state) {
      return state.pageData?.body?.canvas?.rows ? state.pageData.body.canvas.rows
        .find((item) => !!item.rowSortableGrid)
        ?.rowSortableGrid : undefined;
    },
    getFacets(state, getters) {
      return getters.getRowSortableGrid?.zones?.[0]?.facets?.facets || [];
    },
    getSelectedFacets(state, getters) {
      return getters.getRowSortableGrid?.zones?.[0]?.facets?.meta?.selectedFacets || {};
    },
    getPageDataProperties(state) {
      return state.pageData?.meta?.properties;
    },
    getFacetMetaBaseUrl(state, getters) {
      const uniqueSearchPatternEnabled = getters.getPageDataProperties?.uniqueSearchPatternEnabled;
      const facetMeta = getters.getRowSortableGrid?.zones?.[0]?.facets?.meta;
      const metaBaseUrl = facetMeta?.baseUrl;

      // for both brands
      const isSearchToFeatured = uniqueSearchPatternEnabled && metaBaseUrl?.startsWith('/shop/search');
      const isSearch = state.pageData?.meta?.pageType?.search;

      let baseUrl = metaBaseUrl;
      if (isSearch && isSearchToFeatured) {
        const queryParams = new URLSearchParams(facetMeta?.queryString || '');
        const keyword = queryParams.get('keyword');
        const encoded = encodeSearchKeyword(keyword, brand === 'bcom');
        baseUrl = `/shop/featured/${encoded}`;
      }

      return baseUrl;
    },
    getSelectedFacetUrl(state, getters) {
      let selectedFacetUrl = getters.getRowSortableGrid?.zones?.[0]?.facets?.meta?.selectedFacetUrl;
      if (typeof selectedFacetUrl === 'string') {
        selectedFacetUrl = selectedFacetUrl.replace(/\|/g, '%7C');
      }
      return selectedFacetUrl;
    },
    getPage(state) {
      return state.pageData;
    },
    getAnalyticsData(state) {
      return state.pageData?.meta?.analytics?.data;
    },
    getModel(state, getters) {
      if (!state.pageData?.body?.canvas?.rows) return {};
      const { sortableGrid } = getters.getRowSortableGrid?.zones?.[1] || {};
      return sortableGrid?.model;
    },
    getProductCount(state, getters) {
      return getters.getRowSortableGrid?.zones?.[0].facets?.meta?.productCount || 0;
    },
    getDisableDeliveryAndFulfillmentFacet(state, getters) {
      return getters.getMeta?.pageType?.disableDeliveryAndFulfillmentFacet || false;
    },
    getSelectedFacetCount(state, getters) {
      const selectedFacets = getters.getSelectedFacets;
      return Object.values(selectedFacets).flatMap?.((values) => values)?.length || 0;
    },
    getFacetsMeta(state, getters) {
      return getters.getRowSortableGrid?.zones?.[0].facets?.meta;
    },
    getSortOptions(state, getters) {
      const model = getters.getModel || null;
      let selected = null;
      let options = [];
      if (!model || !model.sort) return { options, selected };
      options = model.sort.map(({ value, name, isSelected }) => {
        const option = { value, displayValue: name };
        if (isSelected) {
          selected = option;
        }

        return option;
      });
      return { options, selected };
    },
    getSortOptionsForFacets(state, getters) {
      const model = getters.getModel || null;
      const selectedSortOption = model.sort?.map((item) => ({
        ...item,
        displayValue: item.name,
      }));
      return selectedSortOption;
    },
    getCurrentSortby(state) {
      const pageSortby = state.pagination.find((item) => item.key === 'Sortby');
      return pageSortby.value;
    },
    getCurrentPageindex(state) {
      const pagePageindex = state.pagination.find((item) => item.key === 'Pageindex');
      return pagePageindex.value;
    },
    getCurrentProductsperpage(state) {
      const pageProductsperpage = state.pagination.find((item) => item.key === 'Productsperpage');
      return pageProductsperpage.value;
    },
    getPathUrls(state) {
      const result = state.pagination
        .filter((item) => !(
          (item.key === 'Sortby' && item.value === 'ORIGINAL')
        || (item.key === 'Pageindex' && item.value === 1)
        || (item.key === 'Productsperpage' && item.value === 60)
        ))
        .map(({ key, value }) => ({ key, value }));
      return result;
    },
    getPagination(state, getters) {
      const model = getters.getModel || null;
      const defaultPerPage = 60;
      let pageIndex = getters.getCurrentPageindex || 1;
      let perPage = defaultPerPage;
      let selected = null;
      if (!model || !model.pagination) return { pageIndex, perPage };
      pageIndex = model.pagination.currentPage === 0 ? pageIndex : model.pagination.currentPage;
      perPage = Number(model.perPage.filter((page) => page.isSelected)[0].name) || defaultPerPage;
      selected = model.sort.find(({ isSelected }) => isSelected);
      const sortBy = selected ? selected.value : 'ORIGINAL';
      return { pageIndex, perPage, sortBy };
    },
    getPaginationKeyValue(state, getters) {
      const { pageIndex, perPage, sortBy } = getters.getPagination;

      const paginationArray = [
        { key: 'Pageindex', value: pageIndex },
        { key: 'Productsperpage', value: perPage },
        { key: 'Sortby', value: sortBy },
      ];

      const filteredPaginationArray = paginationArray.filter(({ key, value }) => {
        if (key === 'Sortby' && value !== 'ORIGINAL') return true;
        if (key === 'Productsperpage' && value !== 60) return true;
        return key === 'Pageindex';
      });

      return filteredPaginationArray;
    },
    getPzoItems(state) {
      return state?.pzoEligibleItems?.List?.map((x) => x.productId) || [];
    },

    getProductIds(state) {
      if (!state?.pageData?.body?.canvas?.rows) return [];
      const { sortableGrid } = state?.pageData?.body?.canvas?.rows
        .find((item) => item.rowSortableGrid)
        ?.rowSortableGrid.zones[1] || {};
      const productIds = [];
      sortableGrid?.collection.forEach((val) => {
        productIds.push(val.product?.id);
      });
      return productIds;
    },

    getMeta(state) {
      return state.pageData?.meta;
    },

    getMetaContext(state, getters) {
      return getters.getMeta?.context;
    },

    getCollection(state) {
      if (!state.pageData?.body?.canvas?.rows) return [];
      const { sortableGrid } = state.pageData?.body?.canvas?.rows
        .find((item) => item.rowSortableGrid)
        ?.rowSortableGrid.zones[1] || {};

      return sortableGrid?.collection;
    },

    getFilterOptimizationEnabled(state) {
      return state.filterOptimizationEnabled;
    },

    getExperimentIds(state) {
      return state.pageData?.meta?.context?.customerExperiment?.split(',').map((id) => id.trim()) || [];
    },
  },
  mutations: {
    SET_PAGE(state, payload) {
      state.pageData = { ...state.pageData, ...payload };
    },
    SET_PATHURLS(state, payload) {
      payload.forEach(({ key, value }) => {
        const index = state.pagination.findIndex((item) => item.key.toLowerCase() === key.toLowerCase());
        if (index !== -1) {
          state.pagination[index].value = value;
        }
      });
    },
    SET_PZOURL(state, payload) {
      state.pzoEligibleItems = payload;
    },
    SET_FILTER_OPTIMIZATION_ENABLED(state, payload) {
      state.filterOptimizationEnabled = payload;
    },
  },
  actions: {
    SET_PAGE({ commit }, payload) {
      commit('SET_PAGE', payload);
    },
    SET_PATHURLS({ commit }, payload) {
      commit('SET_PATHURLS', payload);
    },
  },
  modules: {
    moreLikeThisStore,
    visualFilterStore,
    modalOverlayStore,
    recommendationStore,
    FilteringFacetsStore,
    wishlistStore,
    googleAdStore,
    sponsoredItemsStore,
    imageMapStore,
  },
};
